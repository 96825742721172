import React from 'react'
import { Wrapper } from './Styled-components'

const Content = () => {
  return (
    <>
      <Wrapper>
        <h1>TERMS AND CONDITIONS</h1>
        <h6>Application and entire agreement </h6>
        <p>
          1. These Terms and Conditions apply to the provision of the services
          detailed in our quotation ( ) by Services Influence
        </p>
        <p>
          a company registered in under number whose registered office is
          Business Services LTD England and Wales 12171457 ( or or ) to the
          person at , 7 Ridgway Road, Barton Seagrave, Kettering, Northants.
          NN15 5AQ we as Service Provider buying the services ( or ). you
          Customer 2. You are deemed to have accepted these Terms and Conditions
          when you accept our quotation or from the date of any performance of
          the Services (whichever happens earlier) and these Terms and
          Conditions and our quotation (the ) are the entire agreement between
          us. Contract 3. You acknowledge that you have not relied on any
          statement, promise or representation made or given by or on our
          behalf. These Conditions apply to the Contract to the exclusion of any
          other terms that you try to impose or incorporate, or which are
          implied by trade, custom, practice or course of dealing.
        </p>

        <h6>Interpretation </h6>
        <p>
          4. A "business day" means any day other than a Saturday, Sunday or
          bank holiday in . England and Wales 5. The headings in these Terms and
          Conditions are for convenience only and do not affect their
          interpretation. 6. Words imparting the singular number shall include
          the plural and vice-versa.{' '}
        </p>

        <h6>Services </h6>
        <p>
          7. We warrant that we will use reasonable care and skill in our
          performance of the Services which will comply with the quotation,
          including any specification in all material respects. We can make any
          changes to the Services which are necessary to comply with any
          applicable law or safety requirement, and we will notify you if this
          is necessary. 8. We will use our reasonable endeavours to complete the
          performance of the Services within the time agreed or as set out in
          the quotation; however, time shall not be of the essence in the
          performance of our obligations. 9. All of these Terms and Conditions
          apply to the supply of any goods as well as Services unless we specify
          otherwise.
        </p>

        <h6>Your obligations </h6>
        <p>
          10. You must obtain any permissions, consents, licences or otherwise
          that we need and must give us with access to any and all relevant
          information, materials, properties and any other matters which we need
          to provide the Services. 11. If you do not comply with clause 10, we
          can terminate the Services. 12. We are not liable for any delay or
          failure to provide the Services if this is caused by your failure to
          comply with the provisions of this section ( ). Your obligations{' '}
        </p>

        <h6>Fees and Deposit </h6>
        <p>
          13. The fees ( ) for the Services are set out in the quotation and are
          on a time and materials basis. Fees 14. In addition to the Fees, we
          can recover from you a) reasonable incidental expenses including, but
          not limited to, travelling expenses, hotel costs, subsistence and any
          associated expenses, b) the cost of services provided by third parties
          and required by us for the performance of the Services, and c) the
          cost of any materials required for the provision of the Services. 15.
          You must pay us for any additional services provided by us that are
          not specified in the quotation in accordance with our then current,
          applicable rate in effect at the time of performance or such other
          rate as may be agreed between daily us. The provisions of clause 14
          also apply to these additional services. 16. The Fees are exclusive of
          any applicable VAT and other taxes or levies which are imposed or
          charged by any competent authority. 17. You must pay a deposit
          ("Deposit") as detailed in the quotation within 7 days of acceptance.
          18. If you do not pay the Deposit to us according to the clause above,
          we can either withhold provision of the Services until the Deposit is
          received or can terminate under the clause below ( Termination ). 19.
          The Deposit is non-refundable unless we fail to provide the Services
          and are at fault for such failure (where the failure is not our fault,
          no refund will be made).
          <br />
          <br /> Cancellation and amendment 20.
          <br />
          <br /> We can withdraw, cancel or amend a quotation if it has not been
          accepted by you, or if the Services have not started, within a period
          of 14 days from the date of the quotation, (unless the quotation has
          been withdrawn). 21. Either we or you can cancel an order for any
          reason prior to your acceptance (or rejection) of the quotation. 22.
          If you want to amend any details of the Services you must tell us in
          writing as soon as possible. We will use reasonable endeavours to make
          any required changes and additional costs will be included in the Fees
          and invoiced to you. 23. If, due to circumstances beyond our control,
          including those set out in the clause below (Circumstances beyond a
          party's control ), we have to make any change in the Services or how
          they are provided, we will notify you immediately. We will use
          reasonable endeavours to keep any such changes to a minimum.
          <br />
          <br /> Payment
          <br />
          <br />
          24. We will invoice you for payment of the Fees either: a. when we
          have completed the Services; or b. on the invoice dates set out in the
          quotation. 25. You must pay the Fees due within 30 days of the date of
          our invoice or otherwise in accordance with any credit terms agreed
          between us. 26. Time for payment shall be of the essence of the
          Contract. 27. Without limiting any other right or remedy we have for
          statutory interest, if you do not pay within the period set out above,
          we will charge you interest at the rate of 5 % per annum above the
          base lending rate of the Bank of England from time to time on the
          amount outstanding until payment is received in full. 28. All payments
          due under these Terms and Conditions must be made in full without any
          deduction or withholding except as required by law and neither of us
          can assert any credit, set-off or counterclaim against the other in
          order to justify withholding payment of any such amount in whole or in
          part. 29. If you do not pay within the period set out above, we can
          suspend any further provision of the Services and cancel any future
          services which have been ordered by, or otherwise arranged with, you.
          30. Receipts for payment will be issued by us only at your request.
          31. All payments must be made in British Pounds unless otherwise
          agreed in writing between us. <br />
          <br />
          Sub-Contracting and assignment 32.
          <br />
          <br /> We can at any time assign, transfer, charge, subcontract or
          deal in any other manner with all or any of our rights under these
          Terms and Conditions and can subcontract or delegate in any manner any
          or all of our obligations to any third party. 33. You must not,
          without our prior written consent, assign, transfer, charge,
          subcontract or deal in any other manner with all or any of your rights
          or obligations under these Terms and Conditions. <br />
          <br />
          Termination
          <br />
          <br />
          Termination 34. We can terminate the provision of the Services
          immediately if you: a. commit a material breach of your obligations
          under these Terms and Conditions; or b. fail to make pay any amount
          due under the Contract on the due date for payment; or c. are or
          become or, in our reasonable opinion, are about to become, the subject
          of a bankruptcy order or take advantage of any other statutory
          provision for the relief of insolvent debtor; or d. enter into a
          voluntary arrangement under Part 1 of the Insolvency Act 1986 , or any
          other scheme or arrangement is made with its creditors; or e. convene
          any meeting of your creditors, enter into voluntary or compulsory
          liquidation, have a receiver, manager, administrator or administrative
          receiver appointed in respect of your assets or undertakings or any
          part of them, any documents are filed with the court for the
          appointment of an administrator in respect of you, notice of intention
          to appoint an administrator is given by you or any of your directors
          or by a qualifying floating charge holder (as defined in para. 14 of
          Schedule B1 of the Insolvency Act 1986) , a resolution is passed or
          petition presented to any court for your winding up or for the
          granting of an administration order in respect of you, or any
          proceedings are commenced relating to your insolvency or possible
          insolvency. <br />
          <br />
          Intellectual property
          <br />
          <br /> 35. We reserve all copyright and any other intellectual
          property rights which may subsist in any goods supplied in connection
          with the provision of the Services. We reserve the right to take any
          appropriate action to restrain or prevent the infringement of such
          intellectual property rights. <br />
          <br />
          Liability and indemnity
          <br />
          <br /> 36. Our liability under these Terms and Conditions, and in
          breach of statutory duty, and in tort or misrepresentation or
          otherwise, shall be limited as set out in this section. 37. The total
          amount of our liability is limited to the total amount of Fees payable
          by you under the Contract. 38. We are not liable (whether caused by
          our employees, agents or otherwise) in connection with our provision
          of the Services or the performance of any of our other obligations
          under these Terms and Conditions or the quotation for: a. any
          indirect, special or consequential loss, damage, costs, or expenses
          or; b. any loss of profits; loss of anticipated profits; loss of
          business; loss of data; loss of reputation or goodwill; business
          interruption; or, other third party claims; or c. any failure to
          perform any of our obligations if such delay or failure is due to any
          cause beyond our reasonable control; or d. any losses caused directly
          or indirectly by any failure or your breach in relation to your
          obligations; or e. any losses arising directly or indirectly from the
          choice of Services and how they will meet your requirements or your
          use of the Services or any goods supplied in connection with the
          Services. 39. You must indemnify us against all damages, costs, claims
          and expenses suffered by us arising from any loss or damage to any
          equipment (including that belonging to third parties) caused by you or
          your agents or employees. 40. Nothing in these Terms and Conditions
          shall limit or exclude our liability for death or personal injury
          caused by our negligence, or for any fraudulent misrepresentation, or
          for any other matters for which it would be unlawful to exclude or
          limit liability. <br />
          <br />
          Data Protection
          <br />
          <br /> 41. When supplying the Services to the Customer, the Service
          Provider may gain access to and/or acquire the ability to transfer,
          store or process personal data of employees of the Customer. 42. The
          parties agree that where such processing of personal data takes place,
          the Customer shall be the 'data controller' and the Service Provider
          shall be the 'data processor' as defined in the General Data
          Protection Regulation ( GDPR ) as may be amended, extended and/or
          re-enacted from time to time. 43. For the avoidance of doubt,
          'Personal Data', 'Processing', 'Data Controller', 'Data Processor' and
          'Data Subject' shall have the same meaning as in the GDPR. 44. The
          Service Provider shall only Process Personal Data to the extent
          reasonably required to enable it to supply the Services as mentioned
          in these terms and conditions or as requested by and agreed with the
          Customer, shall not retain any Personal Data longer than necessary for
          the Processing and refrain from Processing any Personal Data for its
          own or for any third party's purposes. 45. The Service Provider shall
          not disclose Personal Data to any third parties other than employees,
          directors, agents, sub- contractors or advisors on a strict
          'need-to-know' basis and only under the same (or more extensive)
          conditions as set out in these terms and conditions or to the extent
          required by applicable legislation and/or regulations. 46. The Service
          Provider shall implement and maintain technical and organisational
          security measures as are required to protect Personal Data Processed
          by the Service Provider on behalf of the Customer. 47. Further
          information about the Service Provider's approach to data protection
          are specified in its Data Protection Policy, which can be found On our
          website . For any enquiries or complaints regarding data privacy, you
          can email: david@suada.com . <br />
          <br />
          Circumstances beyond a party's control
          <br />
          <br />
          48. Neither of us is liable for any failure or delay in performing our
          obligations where such failure or delay results from any cause that is
          beyond the reasonable control of that party. Such causes include, but
          are not limited to: industrial action, civil unrest, fire, flood,
          storms, earthquakes, acts of terrorism, acts of war, governmental
          action or any other event that is beyond the control of the party in
          question. If the delay continues for a period of 90 days, either of us
          may terminate or cancel the Services to be carried out under these
          Terms and Conditions. <br />
          <br />
          Communications
          <br />
          <br /> 49. All notices under these Terms and Conditions must be in
          writing and signed by, or on behalf of, the party giving notice (or a
          duly authorised officer of that party). 50. Notices shall be deemed to
          have been duly given: a. when delivered, if delivered by courier or
          other messenger (including registered mail) during the normal business
          hours of the recipient; b. when sent, if transmitted by fax or email
          and a successful transmission report or return receipt is generated;
          c. on the fifth business day following mailing, if mailed by national
          ordinary mail; or d. on the tenth business day following mailing, if
          mailed by airmail. 51. All notices under these Terms and Conditions
          must be addressed to the most recent address, email address or fax
          number notified to the other party. <br />
          <br />
          No waiver
          <br />
          <br /> 52. No delay, act or omission by a party in exercising any
          right or remedy will be deemed a waiver of that, or any other, right
          or remedy nor stop further exercise of any other right, or remedy.{' '}
          <br />
          <br />
          Severance
          <br />
          <br /> 53. If one or more of these Terms and Conditions is found to be
          unlawful, invalid or otherwise unenforceable, that / those provisions
          will be deemed severed from the remainder of these Terms and
          Conditions (which will remain valid and enforceable).
        </p>
        <h6>Law and jurisdiction </h6>
        <p>
          54. This Agreement shall be governed by and interpreted according to
          the law of and all disputes England and Wales arising under the
          Agreement (including non-contractual disputes or claims) shall be
          subject to the exclusive jurisdiction of the courts. English and Welsh
        </p>
      </Wrapper>
    </>
  )
}

export default Content
