import styled from 'styled-components'

export const Wrapper = styled.section`
  font-family: 'Poppins', sans-serif;
  padding: 5%;
  background-color: #fafafa;
  margin: 5% 15%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: ${(props) => props.theme.breakpoints.wxs}) {
    margin: 20px;
    padding: 30px;
  }
  table,
  th,
  td {
    border: 1px solid black;
    padding: 10px;
  }

  p {
    margin-block: 10px;
  }

  h6 {
    font-family: 'Poppins-SemiBold', sans-serif;
    margin-top: 30px;
    font-size: 1.2rem;
  }

  ul .inner {
    margin-left: 20px;
  }
  ol li {
    margin-block: 10px;
  }
`
