import React from 'react'
import { Helmet } from 'react-helmet'
import { BannerNormalizer } from 'sections/ContactUs/styled-components'
import Content from 'sections/Terms/Content'

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet title="Privacy Policy" />
      <BannerNormalizer />
      <Content />
    </>
  )
}

export default PrivacyPolicy
